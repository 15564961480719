import React from 'react';
import { Carousel } from 'react-carousel-minimal';
import './App.css';

const App = () => {
  const data = [{ image: "images/APPLE.JPG" },
  { image: "images/Aqva.jpg" },
  { image: "images/Bolero.jpg" },
  { image: "images/ChicagoNights.JPG", caption: "Chicago Nights" },
  { image: "images/Cloun2.jpg", caption: "Clown" },
  { image: "images/DSCN0003.JPG", caption: "Flowers" },
  { image: "images/DSCN0004.JPG", caption: "Mask" },
  { image: "images/DSCN0005.JPG", caption: "Beauty of the Sea" },
  { image: "images/DSCN0006.JPG", caption: "Poppies" },
  { image: "images/DSCN0007.JPG", caption: "Circus" },
  { image: "images/DSCN0008.JPG", caption: "Angels in the Dark" },
  { image: "images/DSCN0010.JPG", caption: "Love" },
  { image: "images/DSCN0011.JPG", caption: "Woman from the Ship" },
  { image: "images/DSCN0012.JPG" },
  { image: "images/DSCN0013.JPG", caption: "Funny Birds" },
  { image: "images/DSCN0014.JPG", caption: "Purple Puzzle" },
  { image: "images/DSCN0015.JPG", caption: "The Running Notes" },
  { image: "images/DSCN0028_small.jpg", caption: "The Musical Mood" },
  { image: "images/DSCN0700_SMALL.jpg" },
  { image: "images/Lady_Circle.JPG" },
  { image: "images/MakiPastel.jpg" },
  { image: "images/Naturmort.jpg" },
  { image: "images/OnTheMoon.jpg", caption: "On the Moon" },
  { image: "images/People.jpg" },
  { image: "images/RAIN_IN_PARIS.JPG", caption: "Rain in Paris" },
  { image: "images/Rabsodia.jpg" },
  { image: "images/SheandHe.jpg", caption: "She and He" },
  { image: "images/TRIPTYCH.JPG" },
  { image: "images/TRIPTYCH_black.JPG" },
  { image: "images/TRIPTYCH_gray.JPG" },
  { image: "images/TRIPTYCH_white.JPG" },
  { image: "images/TRIPTYCH_white_small.jpg" },
  { image: "images/TriptixGlyzer.jpg" },
  { image: "images/abstract_art1.jpg" },
  { image: "images/abstract_art2.jpg" },
  { image: "images/abstract_art3.jpg" },
  { image: "images/abstract_art4.jpg" },
  { image: "images/astrs.jpg" },
  { image: "images/berry on the tree.jpg", caption: "Berry on the Tree" },
  { image: "images/black_alien_small.jpg" },
  { image: "images/black_white.jpg" },
  { image: "images/blue_alien_small.jpg" },
  { image: "images/blue_flowers.jpg" },
  { image: "images/boat in the sea.jpg", caption: "Boat in the Sea" },
  { image: "images/boat.jpg", caption: "Boat" },
  { image: "images/candle.jpg", caption: "Candle" },
  { image: "images/cloun.jpg", caption: "Clown 2" },
  { image: "images/couple_na_share_small.jpg" },
  { image: "images/dancing couple.jpg", caption: "Dancing Couple" },
  { image: "images/dansing_notes.jpg", caption: "Dancing Notes" },
  { image: "images/daydream.png", caption: "Day Dream" },
  { image: "images/fantazia-summer.jpg", caption: "Fantazia Summer" },
  { image: "images/fantazia1_small.jpg" },
  { image: "images/fantazia2.jpg" },
  { image: "images/fire1.jpg", caption: "Fire" },
  { image: "images/foliage.jpg", caption: "Foliage" },
  { image: "images/lake.jpg", caption: "Lake" },
  { image: "images/lovingcouple.jpg", caption: "Loving Couple" },
  { image: "images/maki.jpg" },
  { image: "images/maki1.jpg" },
  { image: "images/masha.jpg", caption: "Masha" },
  { image: "images/paradizebird.jpg", caption: "Paradise Bird" },
  { image: "images/puppi.jpg", caption: "Poppie" },
  { image: "images/road to nowhere.jpg", caption: "Road to Nowhere" },
  { image: "images/road_after_rain.jpg", caption: "Road after Rain" },
  { image: "images/romashki1.jpg" },
  { image: "images/sea.jpg" },
  { image: "images/smetenie.jpg" },
  { image: "images/sunflowers.jpg" },
  { image: "images/twins.jpg", caption: "Twins" },
  { image: "images/vasilki.jpg" },
  { image: "images/wanda.jpg" },
  { image: "images/whiteandblack.jpg" },
  { image: "images/zebra.jpg", caption: "Zebra" }];
  return (
    <div className="App">
      <center>


        <div className="App-header">
          <img src="images/eoa2.png" alt="Elenas Original Art!" style={{ width: 230 }} />
        </div>
        <Carousel
          data={data}
          time={5000}
          captionStyle={{
            fontSize: '2em',
            fontWeight: 'bold',
          }}
          captionPosition="bottom"
          slideNumber
          width="850px"
          height="500px"
          radius="10px"
          thumbnails
          automatic
          slideImageFit="contain"
          pauseIconColor="white"
          pauseIconSize="40px"
        />
      </center>
    </div>
  );
}

export default App;
